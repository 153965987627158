@use "../../styles/variables";

.profile-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-item {
    width: 35%;
    padding: 20px;
    border-radius: 10px;
    background-color: variables.$whiteColor;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 10px;
      margin: 15px;
    }
  }

  &-btn {
    width: auto;
  }
}

.col {
  display: flex;
  flex-direction: column;

  & > label {
    font-size: 100%;
  }

  & > input {
    padding: 11px;
    border: 1px solid variables.$blackColor;
  }
}
