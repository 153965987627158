@use "../../styles/variables";

.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 100%;

  & thead tr {
    background-color: variables.$fontColor;
    color: variables.$whiteColor;
    text-align: left;
  }

  & thead tr:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  & th,
  td {
    padding: 12px 15px;
  }

  & tbody tr {
    border-top: 1px solid variables.$fontColor;
    background-color: variables.$whiteColor;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}

.table-mobile {
  display: flex;
  flex-direction: column;
  padding: 5px 0 5px 0;

  &-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: variables.$whiteColor;
    border: 1px solid variables.$mainColor;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin: 4px auto;
    & > ul > li {
      list-style-type: none;

      & > span {
        font-weight: bold;
        margin-right: 2px;
      }
    }

    & > div {
      align-items: center;
      display: flex;
      width: 25%;
      flex-grow: 0;
      justify-content: space-evenly;
    }
  }
}

.container-table {
  display: flex;
  flex-direction: column;
  margin: 1% 2% 1% 2%;
  padding: 10px;
  background-color: variables.$whiteColor;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.btn-link {
  padding: 10px;
  background-color: variables.$fontColor;
  width: 100px;
  border-radius: 8px;
  color: variables.$whiteColor;
  border: 4px solid variables.$fontColor;
}

.btn-link:hover {
  border: 4px solid #b4c8d7;
}

.btn-tooltip {
  position: relative;
}

.tooltip-content {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.btn-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  position: relative;
  display: inline-block;
}

.dropdown-menu-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  & > ul > li {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    &:hover {
      cursor: pointer;
      background-color: variables.$mainColor;
      color: variables.$whiteColor;
    }
  }
}

.dropdown-menu:hover .dropdown-menu-content {
  display: block;
}

.pagination-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;

  & > .pagination {
    color: variables.$whiteColor;
    width: auto;
    display: flex;
    justify-content: space-around;
    background: variables.$fontColor;
    padding: 9px;
    border-radius: variables.$borderRadius;
    & > li {
      padding: 5px 9px 5px 9px;
      list-style-type: none;

      &:hover {
        cursor: pointer;
      }
    }

    @media screen and (max-width: 725px) {
      & {
        width: 100%;
      }
    }
  }
}

.active-pagination {
  background-color: variables.$mainColor;
  font-weight: bold;
}
