@use "../../styles/variables";

.select-lang {
  position: relative;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &-item {
    padding: 5px;
    border: 1px solid variables.$mainColor;
  }
}

.select-lang-content {
  display: flex;
  flex-direction: column;
  width: 120px;
  border: 1px solid variables.$mainColor;

  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: -59px;
  z-index: 9;
  background: variables.$whiteColor;

  &-item {
    display: flex;
    padding: 5px;
    flex-direction: row;
    justify-content: space-between;
    color: variables.$fontColor;

    &:hover {
      cursor: pointer;
      //background-color: variables.$mainColor;
      color: variables.$mainColor;
    }
  }
}
