@use "./styles/variables";
@import "./styles/_font";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: variables.$mainColor;
  line-height: 1.2;
  color: variables.$fontColor;
  min-height: 100vh;
  min-height: var(--window-height);
  font-size: 16px;
}

a {
  text-decoration: none;
}

#root {
  min-height: 100%;
}

.btn,
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

span {
  font-size: 14px;
}

small {
  text-align: center;
}

button {
  outline: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  background: transparent;
  color: variables.$fontColor;
  font-size: 16px;
}

input {
  outline: none;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
}

h3,
h4,
h5,
select {
  font-weight: 100;
  font-size: 100%;
}

.block {
  &:not(:first-child) {
    padding-top: 80px;
  }
  &-space {
    padding-top: 0.25rem;
    hr {
      border-color: variables.$mainColor;
    }
    &-top {
      padding-top: 0;
      padding-bottom: 0.25rem;
      hr {
        border-color: variables.$mainColor;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*Content Editable placeholder for input*/
.field-input {
  &.date[placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    background-color: transparent;
  }
}

[placeholder]:empty:before {
  content: attr(placeholder);
  color: #888;
  font-style: italic;
}
