@use "../../styles/variables";

.container {
  overflow: hidden;
  position: relative;
  max-width: 100%;

  &-list-item {
    width: 320px;
    position: absolute;
    top: 10%;
    left: 0;
    border-radius: 10px;
    background-color: variables.$whiteColor;
    border: 1.5px solid variables.$mainColor;
    transition: 1s ease;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    &-btn-polygon {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 12px 6px 12px 0;
      border-bottom: 1px solid variables.$mainColor;
    }

    &-detail-mobile {
      display: flex;
      flex-direction: column;
      height: 185px;
      background-color: variables.$whiteColor;
      border: 1px solid variables.$mainColor;

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      &-body {
        overflow-y: auto;
        & > div {
          height: 100%;
        }
      }
    }
  }
}

.list-item > li {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: row;
  }
}

.closed {
  transition: 1s ease;
  //animation: fadeOut 1s ease infinite;
  width: 0;
}

.btn {
  padding: 10px 5px;
  border: 1px solid variables.$mainColor;
  background-color: variables.$mainColor;
  color: variables.$whiteColor;
  width: 50px;
  margin: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: variables.$whiteColor;
    color: variables.$mainColor;
  }
}

.btn-small {
  padding: 6px 0px;
  width: 40px;
}

.bold {
  font-weight: bold;
}

.lock {
  background-color: variables.$whiteColor;
  border: 1px solid gray;
  pointer-events: none;

  & > i {
    color: gray;
  }

  &:hover {
    cursor: not-allowed;
  }
}

.search-box {
  position: absolute;
  left: 35%;
  top: 2%;
  width: 39%;
  display: flex;

  @media screen and (max-width: 425px) {
    top: 11%;
    width: 95%;
    left: 2%;
  }

  & > div {
    width: 99999999px;
  }

  &-input {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }
}
