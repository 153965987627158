@use "../../styles/variables";

.form-login {
  background-color: #eeb557;
  width: 100%;
  height: 100vh;
  height: var(--window-height);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 425px) {
    &-card {
      margin: 30px;
    }
  }

  &-card {
    border-radius: 16px;
    padding: 10px;
    border: 1px solid variables.$mainColor;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;

    & > img {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    &-sign-in {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px;
    }

    &-input {
      position: relative;
      width: 100%;

      & > label {
        position: relative;
        display: inline-block;
        color: variables.$fontColor;
        top: 100px;
        padding: 4px;
        font-size: 0.805em;
        transition: 0.2s ease;
        z-index: 10;
        position: relative;
        top: -35px;
        right: -14px;
      }

      & > input[type="text"],
      input[type="password"] {
        width: 100%;
        height: 48px;
        position: relative;
        display: inline-block;
        color: black;
        font-size: 1.05em;
        padding: 11px;
        background-color: transparent;
        border: none;
        border: 1px solid variables.$mainColor;
        outline: none;
        z-index: 20;
      }

      & > input[type="text"]:focus ~ label,
      input[type="password"]:focus ~ label,
      input[type="text"].has-value ~ label,
      input[type="password"].has-value ~ label {
        top: -61px;
        background-color: variables.$whiteColor;
        z-index: 20;
      }
    }
  }
}

.btn-login {
  padding: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40%;
  background-color: variables.$mainColor;
  border: 1px solid variables.$mainColor;
  color: variables.$whiteColor;
  border-radius: variables.$borderRadius;

  @media screen and (max-width: 375px) {
    & {
      font-size: 12px;
    }
  }

  &:hover {
    border: 1px solid variables.$mainColor;
    color: variables.$fontColor;
    background-color: variables.$whiteColor !important;
  }
}

.btn-login small {
  margin-top: 20px;
}

small > a:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
