@use "../../../styles/variables";

.modal-device {
  position: fixed;
  left: 0;
  top: -11px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  transform: 1s;
  z-index: 3;
  transform: scale(1);
}

.modal-device-content {
  display: flex;
  flex-direction: column;
  background-color: variables.$whiteColor;
  margin: 15% auto;
  border: 1px solid variables.$mainColor;
  width: 20%;
  border-radius: variables.$borderRadius;

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid variables.$mainColor;
    padding: 0 15px 0 0;
    & > h5 {
      font-size: 20px;
    }
  }

  &-body {
    display: flex;
    padding: 15px;
    height: auto;
    flex-direction: column;

    & > div > input {
      border: 1px solid variables.$mainColor;
      padding: 5px;
    }

    & > div > input:focus {
      border: 1px solid variables.$blackColor;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    color: variables.$whiteColor;

    & > button {
      padding: 15px;
      color: variables.$whiteColor;
      background-color: variables.$mainColor;
      border-radius: variables.$borderRadius;
      border: 1px solid variables.$mainColor;
    }

    & > button:hover {
      background-color: variables.$whiteColor;
      border: 1px solid variables.$mainColor;
      color: variables.$fontColor;
    }
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: variables.$mainColor;
  text-decoration: none;
  cursor: pointer;
}

.block {
  display: block;
  animation: moveDown 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.none {
  display: none;
}

@keyframes moveDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1%);
  }
}
