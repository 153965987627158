.error-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > h5 {
    font-size: 50px;
    font-weight: 200;
  }
}
