@use "../../../styles/variables";

.form-sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  &-input {
    display: flex;
    flex-direction: column;
    margin: 5px;

    & > label {
      font-size: 13px;
      font-weight: bold;
    }

    & > input {
      border: 1px solid variables.$mainColor;
      padding: 11px;
    }

    & > input:focus {
      border: 1px solid black;
    }
  }
}

.w-30 {
  width: 30%;
  @media screen and (max-width: 425px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1440px) {
    width: 65%;
  }
}

.row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    width: 100%;
  }
}

.mandatory {
  color: red;
}
