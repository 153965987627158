@use "../../styles/variables";

.nav {
  width: 100%;
  height: 70px;
  background-color: variables.$blackColor;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;

  @media screen and (max-width: 425px) {
    height: 50px;
  }

  &-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;

    & > li {
      padding: 15px;
      display: flex;
      align-items: center;

      & > a {
        color: variables.$fontColor;
        &:hover {
          color: variables.$whiteColor;
          cursor: pointer;
        }
      }
    }

    & > li:first-child {
      flex-grow: 1;
    }

    & > li:hover {
      color: variables.$whiteColor;
    }
  }
}

.nav-mobile {
  width: 100%;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    background-color: variables.$fontColor;
    border: 1px solid variables.$mainColor;
    left: -800px;
    top: 0;

    &-item {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid variables.$mainColor;
      align-items: center;

      & > a {
        color: variables.$whiteColor;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        margin-left: 15px;
      }
    }
  }
}

.nav-open {
  transition: 1s ease;
  left: 0;
}

.nav-close {
  transition: 1s ease;
  left: -800px;
}

.dashboard {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: calc(100vh - 140px);
  color: variables.$whiteColor;

  @media screen and (max-width: 425px) {
    height: calc(100vh - 120px);
  }

  /* Media query for Safari */
  @media not all and (min-resolution: 0.001dpcm) {
    /* Safari-only media query */
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      /* Height calculation specific to Safari when --window-height is supported */
      height: calc(var(--window-height) - 120px);
    }
  }
}

.body {
  hyphens: none;
  height: calc(100vh - 70px);
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;

  @media screen and (max-width: 425px) {
    height: calc(100vh - 50px);
  }

  /* Media query for Safari */
  @media not all and (min-resolution: 0.001dpcm) {
    /* Safari-only media query */
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      height: calc(var(--window-height) - 50px);
    }
  }

  &-content {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    &-item {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.footer {
  display: flex;
  width: 100%;
  height: 4.5rem;
  background-color: variables.$fontColor;
  border-top: 1px solid variables.$mainColor;
  color: white;
  justify-content: center;
  align-items: center;

  & > p {
    padding: 22px;
    font-size: small;
    font-style: italic;
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  & > span {
    font-size: 100%;
  }
}

.dropdown-content-custom {
  display: none;
  position: absolute;
  background-color: variables.$blackColor;
  border-radius: 10px;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: variables.$whiteColor;

  & > ul > li {
    list-style-type: none;
    padding: 10px;

    & > a {
      color: variables.$whiteColor;
      &:hover {
        color: variables.$mainColor;
      }
    }

    &:last-of-type {
      border-top: 1px solid variables.$whiteColor;
    }

    &:hover {
      cursor: pointer;
      color: variables.$mainColor;
    }
  }
}

.dropdown:hover .dropdown-content-custom {
  display: block;
}
