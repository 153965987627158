@use "../../styles/variables";

.container {
  overflow: hidden;
  position: relative;
  max-width: 100%;

  &-list-item {
    width: 320px;
    position: absolute;
    top: 10%;
    left: 0;
    border-radius: 10px;
    background-color: variables.$whiteColor;
    border: 1.5px solid variables.$mainColor;
    transition: 1s ease;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    & > ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    &-detail {
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      padding: 0 12px 0 12px;
      height: calc(500px - 143px);
      & > ul > li {
        display: flex;
        flex-direction: row;
      }
    }

    &-search {
      padding: 5px;
      border-bottom: 1px solid variables.$mainColor;
      &-date {
        background-color: variables.$whiteColor;
        padding: 5px;
        display: flex;
        position: relative;
        flex-direction: column;

        & > label {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        &-pick {
          padding: 5px;
          border: 2px solid variables.$mainColor;
          border-radius: 6px;
        }

        &-pick:hover {
          cursor: pointer;
        }
      }

      &-status {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        & > label {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        &-select {
          color: variables.$fontColor;
          border: 2px solid variables.$mainColor;
          padding: 5px;
          border-radius: 6px;
        }
      }

      &-btn {
        display: flex;
        justify-content: right;
        margin-top: 5px;

        & > button {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          height: 30px;
          width: 30px;
          background-color: variables.$mainColor;
          border: 1px solid variables.$mainColor;
        }

        & > button:hover {
          cursor: pointer;
          border: 1px solid black;
        }
      }
    }
  }
}

.map-mobile {
  display: flex;
  flex-direction: column;
  height: 285px;
  background-color: variables.$whiteColor;
  border: 1px solid variables.$mainColor;
}

div.item-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

div.item-child:hover {
  cursor: pointer;
}

.closed {
  transition: 1s ease;
  overflow-y: hidden;
  //animation: fadeOut 1s ease infinite;
  width: 0;
}
.date-view {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 18%;
}

.button-close {
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 280px;
  left: 308px;
  width: 22px;
  height: 49px;
  border: 1px solid variables.$mainColor;
  background-color: variables.$mainColor;
  border-radius: 5px;
  transition: 1s ease;
  animation: zoom-in-zoom-out 1s ease infinite;

  &:hover {
    cursor: pointer;
  }
}

.button-linkDevice {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 2px;
  margin-left: 2px;
  height: 40px;
  width: 140px;
  background-color: variables.$mainColor;
  border: 1px solid variables.$mainColor;
  color: black;

  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }
}

.active {
  transition: 1s ease;
  left: 0;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.items {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    text-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid variables.$mainColor;
  }

  &-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
      margin: 2px;
      padding: 9px 8px;
      border-radius: 10px;
      border: 1px solid variables.$mainColor;
      background-color: variables.$whiteColor;
      text-wrap: nowrap;
    }

    & > div.btn-active {
      background-color: variables.$mainColor;
      color: variables.$whiteColor;
    }

    & > div:hover {
      cursor: pointer;
      background-color: variables.$mainColor;
      color: variables.$whiteColor;
    }
  }
}

.marker-info {
  display: flex;
  flex-direction: column;

  & > ul > li {
    display: flex;
    flex-direction: row;

    & > label {
      margin-right: 5px;
      font-weight: bold;
    }
  }
}
