$primary-font: "Century Gothic", sans-serif;
$primary-font-size: 1.1rem;

$paddingInit: 15px;

$mainColor: #eeb557;
$fontColor: #87949e;

$blackColor: #000000;
$whiteColor: #ffff;

$borderRadius: 8px;
